<template>
  <FormSection @submitted="updatePassword">
    <template #title> Update Password </template>

    <template #description>
      Ensure your account is using a long, random password to stay secure.
    </template>

    <template #form>
      <div class="col-span-6 sm:col-span-4">
        <Label for="current_password" value="Current Password" />
        <Input
          id="current_password"
          type="password"
          class="mt-1 block w-full"
          v-model="form.current_password"
          ref="current_password"
          autocomplete="current-password"
        />
        <InputError
          :message="form.errors.get('current_password')"
          class="mt-2"
        />
      </div>

      <div class="col-span-6 sm:col-span-4">
        <Label for="new_password" value="New Password" />
        <Input
          id="new_password"
          type="password"
          class="mt-1 block w-full"
          v-model="form.new_password"
          ref="new_password"
          autocomplete="new-password"
        />
        <InputError :message="form.errors.get('new_password')" class="mt-2" />
      </div>

      <div class="col-span-6 sm:col-span-4">
        <Label for="new_password_confirmation" value="Confirm Password" />
        <Input
          id="new_password_confirmation"
          type="password"
          class="mt-1 block w-full"
          v-model="form.new_password_confirmation"
          autocomplete="new-password"
        />
        <InputError
          :message="form.errors.get('new_password_confirmation')"
          class="mt-2"
        />
      </div>
    </template>

    <template #actions>
      <ActionMessage :on="form.recentlySuccessful" class="mr-3">
        Saved.
      </ActionMessage>

      <Button :class="{ 'opacity-25': form.busy }" :disabled="form.busy">
        Update
      </Button>
    </template>
  </FormSection>
</template>

<script>
import EmployeesService from '@/service/employees/employees.js';
import { defineComponent } from 'vue';
import ActionMessage from '../../../../components/@globals/helpers/ActionMessage';
import Button from '../../../../components/@globals/helpers/Button';
import FormSection from '../../../../components/@globals/helpers/FormSection';
import Input from '../../../../components/@globals/helpers/Input';
import InputError from '../../../../components/@globals/helpers/InputError';
import Label from '../../../../components/@globals/helpers/Label';
import Form from 'vform';

export default defineComponent({
  components: {
    ActionMessage,
    Button,
    FormSection,
    Input,
    InputError,
    Label,
  },

  data() {
    return {
      form: Form.make({
        current_password: '',
        new_password: '',
        new_password_confirmation: '',
      }),

      id: this.$route.params.id,
    };
  },

  methods: {
    updatePassword() {
      EmployeesService.updateEmployeePassword(this.id, this.form)
        .then(() => this.form.reset())
        .catch(() => {
          if (this.form.errors.has('new_password')) {
            this.form.reset('new_password', 'password_confirmation');
            this.$refs.new_password.focus();
          }

          if (this.form.errors.has('current_password')) {
            this.form.reset('current_password');
            this.$refs.current_password.focus();
          }
        });
    },
  },
});
</script>
