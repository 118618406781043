<template>
  <div>
    <SectionHeading
      :title="viewType == 'new' ? 'New' : 'Edit'"
      iconName="ReceiptTaxIcon"
    />
    <LoadingSpinner v-if="viewType == 'edit' && dataLoading" />
    <div class="max-w-7xl py-10 sm:px-6 lg:px-8">
      <FormSection
        @submitted="viewType == 'new' ? addTaxGroup() : updateTaxGroup()"
      >
        <template #title>
          {{ $t('views.configuration.tax_groups.title') }}
        </template>

        <template #description>Manage taxation of your products</template>

        <template #form>
          <div class="col-span-6 sm:col-span-4">
            <Label for="name" value="Nazwa" />
            <Input
              id="name"
              type="text"
              class="mt-1 block w-full"
              v-model="taxGroupData.name"
            />
            <InputError
              :message="taxGroupData.errors.get('name')"
              class="mt-2"
            />
          </div>

          <div class="col-span-6 sm:col-span-4">
            <Label for="identifier" value="Identyfikator" />
            <Input
              id="identifier"
              type="text"
              class="mt-1 block w-full"
              v-model="taxGroupData.identifier"
            />
            <InputError
              :message="taxGroupData.errors.get('identifier')"
              class="mt-2"
            />
          </div>

          <div class="col-span-6 sm:col-span-4">
            <Label for="tax_rate_id" value="Stawka VAT" />
            <TSelect
              id="tax_rate_id"
              :options="taxRateList.data"
              class="mt-1 block w-full"
              v-model="taxGroupData.tax_rate_id"
              valueAttribute="id"
              textAttribute="rate"
            />
            <InputError
              :message="taxGroupData.errors.get('tax_rate_id')"
              class="mt-2"
            />
          </div>
        </template>

        <template #actions>
          <ActionMessage :on="taxGroupData.recentlySuccessful" class="mr-3">
            Saved.
          </ActionMessage>

          <Button
            :class="{ 'opacity-25': taxGroupData.busy }"
            :disabled="taxGroupData.busy"
          >
            {{ viewType == 'new' ? 'Add' : 'Update' }}
          </Button>
        </template>
      </FormSection>
    </div>
  </div>
</template>

<script>
import { computed, ref } from 'vue';
import TaxGroupsService from '@/service/tax-groups/taxGroups.js';
import { useRoute } from 'vue-router';
import LoadingSpinner from '@/components/@globals/helpers/LoadingSpinner';
import FormSection from '../../../components/@globals/helpers/FormSection';
import Input from '../../../components/@globals/helpers/Input';
import InputError from '../../../components/@globals/helpers/InputError';
import Button from '../../../components/@globals/helpers/Button';
import ActionMessage from '../../../components/@globals/helpers/ActionMessage';
import Label from '../../../components/@globals/helpers/Label';
import { TSelect } from '@variantjs/vue';
import { useStore } from 'vuex';
import Form from 'vform';
export default {
  name: 'TaxGroupsAddEdit',
  components: {
    TSelect,
    Button,
    ActionMessage,
    InputError,
    Input,
    FormSection,
    LoadingSpinner,
    Label,
  },
  setup() {
    const store = useStore();
    const {
      params: { id },
    } = useRoute();
    const viewType = ref('');
    const dataLoading = ref(false);
    const taxGroupData = ref(
      Form.make({
        name: '',
        identifier: '',
        tax_rate_id: '',
      })
    );

    store.dispatch('getTaxRates', {});
    const taxRateList = computed(() => store.getters.getTaxRates);

    if (id == 'new') {
      viewType.value = 'new';
    } else {
      viewType.value = 'edit';
      dataLoading.value = true;
      TaxGroupsService.getTaxGroupDetails(id).then((response) => {
        taxGroupData.value.fill(response.data.data);
        taxGroupData.value.tax_rate_id = response.data.data.tax_rate.id;
        dataLoading.value = false;
      });
    }

    function addTaxGroup() {
      TaxGroupsService.addTaxGroup(taxGroupData.value);
    }
    function updateTaxGroup() {
      TaxGroupsService.updateTaxGroup(id, taxGroupData.value);
    }

    return {
      taxGroupData,
      addTaxGroup,
      viewType,
      updateTaxGroup,
      dataLoading,
      taxRateList,
    };
  },
};
</script>
