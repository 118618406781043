<template>
  <div>
    <SectionHeading
      :title="viewType == 'new' ? 'New' : 'Edit'"
      iconName="ColorSwatchIcon"
    />
    <LoadingSpinner v-if="viewType == 'edit' && dataLoading" />
    <div class="max-w-7xl py-10 sm:px-6 lg:px-8">
      <FormSection
        @submitted="viewType == 'new' ? addCategory() : updateCategory()"
      >
        <template #title>
          {{ $t('views.catalog.categories.title') }}
        </template>

        <template #description>Manage categorization of your products</template>

        <template #form>
          <div class="col-span-6 sm:col-span-4">
            <Label for="identifier" value="Identyfikator" />
            <Input
              id="identifier"
              type="text"
              class="mt-1 block w-full"
              v-model="categoryData.identifier"
            />
            <InputError
              :message="categoryData.errors.get('identifier')"
              class="mt-2"
            />
          </div>

          <div class="col-span-6 sm:col-span-4">
            <Label for="parent_id" value="Kategoria rodzic" />
            <TRichSelect
              id="parent_id"
              :options="categoriesList"
              class="mt-1 block w-full"
              v-model="categoryData.parent_id"
              textAttribute="name"
              valueAttribute="id"
              placeholder="Wybierz kategorię"
            >
              <template
                v-slot:option="{
                  //index,
                  //isHighlighted,
                  isSelected,
                  className,
                  option,
                  //query,
                }"
              >
                <button
                  role="option"
                  :class="className"
                  :aria-selected="isSelected"
                  tabindex="-1"
                  type="button"
                  :value="option.id"
                >
                  <div class="flex items-center justify-between px-3 py-2">
                    <span
                      class="block truncate"
                      :class="[
                        option.raw.depth === 0 ? 'font-bold' : 'text-sm',
                        `pl-${option.raw.depth * 4}`,
                      ]"
                      >{{ option.raw.name }}</span
                    >
                  </div>
                </button>
              </template>
            </TRichSelect>
            <InputError
              :message="categoryData.errors.get('parent_id')"
              class="mt-2"
            />
          </div>

          <SectionBorder class="col-span-6" />

          <div class="col-span-6 sm:col-span-4 -mt-6">
            <h3 class="text-lg leading-6 font-medium text-gray-900 pb-2 mb-6">
              Tłumaczenia
            </h3>
            <Disclosure
              as="div"
              v-for="translation in translationsList"
              :key="translation.value"
              class="mt-2"
              v-slot="{ open }"
            >
              <DisclosureButton
                @click="
                  disclosureState[translation.value] =
                    !disclosureState[translation.value]
                "
                class="
                  flex
                  justify-between
                  w-full
                  px-4
                  py-2
                  text-sm
                  font-medium
                  text-left text-blue-900
                  bg-blue-100
                  rounded-lg
                  hover:bg-blue-200
                  focus:outline-none
                  focus-visible:ring
                  focus-visible:ring-blue-500
                  focus-visible:ring-opacity-75
                "
              >
                <span>{{ translation.label }}</span>
                <ChevronDownIcon
                  :class="open ? 'transform rotate-180' : ''"
                  class="w-5 h-5 text-blue-500"
                />
              </DisclosureButton>
              <div v-show="disclosureState[translation.value]">
                <DisclosurePanel
                  static
                  class="pt-4 pb-2 text-sm text-gray-500 space-y-4"
                >
                  <div>
                    <Label value="Nazwa" />
                    <Input
                      type="text"
                      class="mt-1 block w-full"
                      :value="
                        inputValue('translations', translation.value, 'name')
                      "
                      @input="
                        inputEvent(
                          $event,
                          'translations',
                          translation.value,
                          'name'
                        )
                      "
                    />
                    <InputError
                      :message="
                        categoryData.errors.get(
                          'translations.' + translation.value + '.name'
                        )
                      "
                      class="mt-2"
                    />
                  </div>
                  <div>
                    <Label value="Opis" />
                    <Input
                      type="text"
                      class="mt-1 block w-full"
                      :value="
                        inputValue(
                          'translations',
                          translation.value,
                          'description'
                        )
                      "
                      @input="
                        inputEvent(
                          $event,
                          'translations',
                          translation.value,
                          'description'
                        )
                      "
                    />
                    <InputError
                      :message="
                        categoryData.errors.get(
                          'translations.' + translation.value + '.description'
                        )
                      "
                      class="mt-2"
                    />
                  </div>
                  <div>
                    <Label value="Slug" />
                    <Input
                      type="text"
                      class="mt-1 block w-full"
                      :value="
                        inputValue('translations', translation.value, 'slug')
                      "
                      @input="
                        inputEvent(
                          $event,
                          'translations',
                          translation.value,
                          'slug'
                        )
                      "
                    />
                    <InputError
                      :message="
                        categoryData.errors.get(
                          'translations.' + translation.value + '.slug'
                        )
                      "
                      class="mt-2"
                    />
                  </div>
                  <div>
                    <Label value="Tytuł SEO" />
                    <Input
                      type="text"
                      class="mt-1 block w-full"
                      :value="
                        inputValue(
                          'translations',
                          translation.value,
                          'seo_title'
                        )
                      "
                      @input="
                        inputEvent(
                          $event,
                          'translations',
                          translation.value,
                          'seo_title'
                        )
                      "
                    />
                    <InputError
                      :message="
                        categoryData.errors.get(
                          'translations.' + translation.value + '.seo_title'
                        )
                      "
                      class="mt-2"
                    />
                  </div>
                  <div>
                    <Label value="Słowa kluczowe" />
                    <Input
                      type="text"
                      class="mt-1 block w-full"
                      :value="
                        inputValue(
                          'translations',
                          translation.value,
                          'seo_keywords'
                        )
                      "
                      @input="
                        inputEvent(
                          $event,
                          'translations',
                          translation.value,
                          'seo_keywords'
                        )
                      "
                    />
                    <InputError
                      :message="
                        categoryData.errors.get(
                          'translations.' + translation.value + '.seo_keywords'
                        )
                      "
                      class="mt-2"
                    />
                  </div>
                  <div>
                    <Label value="Opis SEO" />
                    <TTextarea
                      class="mt-1 block w-full"
                      :value="
                        inputValue(
                          'translations',
                          translation.value,
                          'seo_description'
                        )
                      "
                      @input="
                        inputEvent(
                          $event,
                          'translations',
                          translation.value,
                          'seo_description'
                        )
                      "
                    />
                    <InputError
                      :message="
                        categoryData.errors.get(
                          'translations.' +
                            translation.value +
                            '.seo_description'
                        )
                      "
                      class="mt-2"
                    />
                  </div>
                </DisclosurePanel>
              </div>
            </Disclosure>
          </div>
        </template>

        <template #actions>
          <ActionMessage :on="categoryData.recentlySuccessful" class="mr-3">
            Saved.
          </ActionMessage>

          <Button
            :class="{ 'opacity-25': categoryData.busy }"
            :disabled="categoryData.busy"
          >
            {{ viewType == 'new' ? 'Add' : 'Update' }}
          </Button>
        </template>
      </FormSection>
    </div>
  </div>
</template>

<script>
import { computed, ref, watchEffect, watch } from 'vue';
import CategoriesService from '@/service/categories/categories.js';
import { useRoute } from 'vue-router';
import LoadingSpinner from '@/components/@globals/helpers/LoadingSpinner';
import FormSection from '../../../components/@globals/helpers/FormSection';
import Input from '../../../components/@globals/helpers/Input';
import InputError from '../../../components/@globals/helpers/InputError';
import Button from '../../../components/@globals/helpers/Button';
import ActionMessage from '../../../components/@globals/helpers/ActionMessage';
import Label from '../../../components/@globals/helpers/Label';
import SectionBorder from '../../../components/@globals/helpers/SectionBorder';
import { TRichSelect, TTextarea } from '@variantjs/vue';
import { useStore } from 'vuex';
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue';
import { ChevronDownIcon } from '@heroicons/vue/outline';
import { TRANSLATIONS } from './category-constants';
import { set, get } from 'lodash';
import Form from 'vform';
import router from '@/router'
export default {
  name: 'CategoriesAddEdit',
  components: {
    Button,
    ActionMessage,
    InputError,
    Input,
    FormSection,
    LoadingSpinner,
    Label,
    TRichSelect,
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    ChevronDownIcon,
    TTextarea,
    SectionBorder,
  },
  setup() {
    const store = useStore();
    const {
      params: { id },
    } = useRoute();
    const viewType = ref('');
    const dataLoading = ref(false);
    const categoryData = ref(
      Form.make({
        identifier: '',
        parent_id: '',
        translations: {},
      })
    );
    const translationsList = TRANSLATIONS;

    store.dispatch('getCategories', {});
    const categoriesList = computed(() => store.getters.getFlattenCategories);

    if (id == 'new') {
      viewType.value = 'new';
    } else {
      viewType.value = 'edit';
      dataLoading.value = true;
      watchEffect(() => {
        if (categoriesList.value.length) {
          CategoriesService.getCategoryDetails(id).then((response) => {
            categoryData.value.fill(response.data.data);
            dataLoading.value = false;
          });
        }
      });
    }

     function addCategory() {
      CategoriesService.addCategory(categoryData.value)
      .then(response => {
        router.push({name: 'CategoriesList'})
      });
    }
     function updateCategory() {
      CategoriesService.updateCategory(id, categoryData.value)
      .then(response => {
        router.push({name: 'CategoriesList'})
      });
    }

    function translationErrorExist(...path) {
      return Object.keys(categoryData.value.errors.all()).filter(function (
        key
      ) {
        return key.includes(path.join('.'));
      }).length;
    }

    const disclosureState = ref({});
    watch(
      () => categoryData.value.errors,
      () => {
        translationsList.forEach(function (translation) {
          if (
            translationErrorExist('translations', translation.value) ||
            localStorage.getItem('app_lang') === translation.value
          ) {
            set(disclosureState.value, translation.value, true);
          }
        });
      },
      { immediate: true, deep: true }
    );

    function inputValue(...fieldName) {
      return get(categoryData.value, fieldName, null);
    }

    function inputEvent(event, ...fieldName) {
      set(categoryData.value, fieldName, event.target.value);
    }

    return {
      categoryData,
      addCategory,
      viewType,
      updateCategory,
      dataLoading,
      categoriesList,
      translationsList,
      translationErrorExist,
      disclosureState,
      inputValue,
      inputEvent,
    };
  },
};
</script>
