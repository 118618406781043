import axios from 'axios';

class MediaService {
  async addMedia(event, tag, contextId) {
    let url = `panel/api/v1/media/${tag}/${contextId}`;
    let formData = new FormData();
    formData.append('media[]', event.target.files[0]);

    return await axios.post(url, formData);
  }
}

export default new MediaService();
