<template>
  <div class="flex flex-col gap-3">
    <jet-section-title>
      <template #title>
        <slot name="title"></slot>
      </template>
      <template #description>
        <slot name="description"></slot>
      </template>
    </jet-section-title>

    <form @submit.prevent="$emit('submitted')">
      <div :class="[
        'px-4 py-5 bg-white sm:p-6 shadow',
        hasActions ? 'sm:rounded-tl-md sm:rounded-tr-md' : 'sm:rounded-md',
      ]">
        <div class="grid grid-cols-6 gap-6">
          <slot name="form"></slot>
        </div>
      </div>

      <div
        v-if="hasActions"
        :class="[
          'flex items-center justify-end',
          'px-4 py-3 bg-gray-50 text-right sm:px-6',
          'shadow sm:rounded-bl-md sm:rounded-br-md',
        ]"
      >
        <slot name="actions"></slot>
      </div>
    </form>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import JetSectionTitle from './SectionTitle.vue';

export default defineComponent({
  emits: ['submitted'],

  components: {
    JetSectionTitle,
  },

  computed: {
    hasActions() {
      return !!this.$slots.actions;
    },
  },
});
</script>
