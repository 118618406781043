<template>
  <div>
    <SectionHeading
      :title="viewType == 'new' ? 'New' : 'Edit'"
      iconName="TagIcon"
    />
    <LoadingSpinner v-if="viewType == 'edit' && dataLoading" />
    <div class="max-w-7xl py-10 sm:px-6 lg:px-8">
      <FormSection
        @submitted="viewType == 'new' ? addAttribute() : updateAttribute()"
      >
        <template #title>
          {{ $t('views.catalog.attributes.title') }}
        </template>

        <template #description>Manage attributes of your products</template>

        <template #form>
          <div class="col-span-6 sm:col-span-4">
            <Label for="identifier" value="Identyfikator" />
            <Input
              id="identifier"
              type="text"
              class="mt-1 block w-full"
              v-model="attributeData.identifier"
            />
            <InputError
              :message="attributeData.errors.get('identifier')"
              class="mt-2"
            />
          </div>

          <div class="col-span-6 sm:col-span-4">
            <Label for="type" value="Typ" />
            <TSelect
              id="type"
              class="mt-1 block w-full"
              v-model="attributeData.type"
              :options="attributeTypeList"
              valueAttribute="value"
              textAttribute="label"
            />
            <InputError
              :message="attributeData.errors.get('type')"
              class="mt-2"
            />
          </div>

          <div class="col-span-6 sm:col-span-4">
            <Label for="is_translatable" value="Tłumaczalny" />
            <TToggle
              id="is_translatable"
              class="mt-1 block"
              v-model="attributeData.is_translatable"
            />
            <InputError
              :message="attributeData.errors.get('is_translatable')"
              class="mt-2"
            />
          </div>

          <SectionBorder class="col-span-6" />

          <div class="col-span-6 sm:col-span-4 -mt-6">
            <h3 class="text-lg leading-6 font-medium text-gray-900 pb-2 mb-6">
              Tłumaczenia
            </h3>
            <Disclosure
              as="div"
              v-for="translation in translationsList"
              :key="translation.value"
              class="mt-2"
              v-slot="{ open }"
            >
              <DisclosureButton
                @click="toggleTranslation(translation.value)"
                class="
                  flex
                  justify-between
                  w-full
                  px-4
                  py-2
                  text-sm
                  font-medium
                  text-left text-blue-900
                  bg-blue-100
                  rounded-lg
                  hover:bg-blue-200
                  focus:outline-none
                  focus-visible:ring
                  focus-visible:ring-blue-500
                  focus-visible:ring-opacity-75
                "
              >
                <span>{{ translation.label }}</span>
                <ChevronDownIcon
                  :class="open ? 'transform rotate-180' : ''"
                  class="w-5 h-5 text-blue-500"
                />
              </DisclosureButton>
              <div v-show="showTranslation(translation.value)">
                <DisclosurePanel
                  static
                  class="pt-4 pb-2 text-sm text-gray-500 space-y-4"
                >
                  <div>
                    <Label value="Nazwa" />
                    <Input
                      type="text"
                      class="mt-1 block w-full"
                      :value="
                        inputValue('translations', translation.value, 'name')
                      "
                      @input="
                        inputEvent(
                          $event,
                          'translations',
                          translation.value,
                          'name'
                        )
                      "
                    />
                    <InputError
                      :message="
                        attributeData.errors.get(
                          'translations.' + translation.value + '.name'
                        )
                      "
                      class="mt-2"
                    />
                  </div>
                  <div>
                    <Label value="Prefix" />
                    <Input
                      type="text"
                      class="mt-1 block w-full"
                      :value="
                        inputValue('translations', translation.value, 'prefix')
                      "
                      @input="
                        inputEvent(
                          $event,
                          'translations',
                          translation.value,
                          'prefix'
                        )
                      "
                    />
                    <InputError
                      :message="
                        attributeData.errors.get(
                          'translations.' + translation.value + '.prefix'
                        )
                      "
                      class="mt-2"
                    />
                  </div>
                  <div>
                    <Label value="Postfix" />
                    <Input
                      type="text"
                      class="mt-1 block w-full"
                      :value="
                        inputValue('translations', translation.value, 'postfix')
                      "
                      @input="
                        inputEvent(
                          $event,
                          'translations',
                          translation.value,
                          'postfix'
                        )
                      "
                    />
                    <InputError
                      :message="
                        attributeData.errors.get(
                          'translations.' + translation.value + '.postfix'
                        )
                      "
                      class="mt-2"
                    />
                  </div>
                </DisclosurePanel>
              </div>
            </Disclosure>
          </div>

          <SectionBorder class="col-span-6" />

          <div v-show="showOptions" class="col-span-6 sm:col-span-4 -mt-6">
            <h3 class="text-lg leading-6 font-medium text-gray-900 pb-2 mb-6">
              Opcje
            </h3>
            <div v-for="(option, i) in attributeData.options" :key="i">
              <Disclosure
                as="div"
                v-for="translation in translationsList"
                :key="translation.value"
                class="mt-2"
                v-slot="{ open }"
              >
                <DisclosureButton
                  @click="toggleTranslation('options', i, translation.value)"
                  class="
                    flex
                    justify-between
                    w-full
                    px-4
                    py-2
                    text-sm
                    font-medium
                    text-left text-indigo-900
                    bg-indigo-100
                    rounded-lg
                    hover:bg-indigo-200
                    focus:outline-none
                    focus-visible:ring
                    focus-visible:ring-indigo-500
                    focus-visible:ring-opacity-75
                  "
                >
                  <span>{{ translation.label }}</span>
                  <ChevronDownIcon
                    :class="open ? 'transform rotate-180' : ''"
                    class="w-5 h-5 text-indigo-500"
                  />
                </DisclosureButton>
                <div v-show="showTranslation('options', i, translation.value)">
                  <DisclosurePanel
                    static
                    class="pt-4 pb-2 text-sm text-gray-500 space-y-4"
                  >
                    <div>
                      <Label value="Nazwa" />
                      <Input
                        type="text"
                        class="mt-1 block w-full"
                        :value="
                          inputValue(
                            'options',
                            i,
                            'translations',
                            translation.value,
                            'name'
                          )
                        "
                        @input="
                          inputEvent(
                            $event,
                            'options',
                            i,
                            'translations',
                            translation.value,
                            'name'
                          )
                        "
                      />
                      <InputError
                        :message="
                          attributeData.errors.get(
                            'options.' +
                              i +
                              '.translations.' +
                              translation.value +
                              '.name'
                          )
                        "
                        class="mt-2"
                      />
                    </div>
                  </DisclosurePanel>
                </div>
              </Disclosure>
              <DynamicIconSolid
                @click="removeOption(i)"
                icon="TrashIcon"
                class="text-red-500 h-6 w-6 mt-2 mb-6 cursor-pointer"
                aria-hidden="true"
              />
            </div>
            <SecondaryButton @click="addOption">
              <DynamicIconSolid
                icon="PlusIcon"
                class="mr-2 -ml-1 h-5 w-5"
                aria-hidden="true"
              />
              Add Option</SecondaryButton
            >
          </div>
        </template>

        <template #actions>
          <ActionMessage :on="attributeData.recentlySuccessful" class="mr-3">
            Saved.
          </ActionMessage>

          <Button
            :class="{ 'opacity-25': attributeData.busy }"
            :disabled="attributeData.busy"
          >
            {{ viewType == 'new' ? 'Add' : 'Update' }}
          </Button>
        </template>
      </FormSection>
    </div>
  </div>
</template>

<script>
import { computed, ref, watch } from 'vue';
import AttributesService from '@/service/attributes/attributes.js';
import { useRoute } from 'vue-router';
import LoadingSpinner from '@/components/@globals/helpers/LoadingSpinner';
import FormSection from '../../../components/@globals/helpers/FormSection';
import Input from '../../../components/@globals/helpers/Input';
import InputError from '../../../components/@globals/helpers/InputError';
import Button from '../../../components/@globals/helpers/Button';
import Label from '../../../components/@globals/helpers/Label';
import { TToggle, TSelect } from '@variantjs/vue';
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue';
import { ChevronDownIcon } from '@heroicons/vue/outline';
import { TRANSLATIONS } from '../categories/category-constants';
import {
  ATTRIBUTE_TYPE_LIST,
  ATTRIBUTES_WITH_OPTIONS,
} from './attribute-constants';
import SectionBorder from '../../../components/@globals/helpers/SectionBorder';
import SecondaryButton from '../../../components/@globals/helpers/SecondaryButton';
import ActionMessage from '../../../components/@globals/helpers/ActionMessage';
import { set, get, unset } from 'lodash';
import Form from 'vform';
export default {
  name: 'AttributesAddEdit',
  components: {
    SecondaryButton,
    SectionBorder,
    Button,
    InputError,
    Input,
    FormSection,
    LoadingSpinner,
    Label,
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    ChevronDownIcon,
    TToggle,
    TSelect,
    ActionMessage,
  },
  setup() {
    const {
      params: { id },
    } = useRoute();
    const viewType = ref('');
    const dataLoading = ref(false);
    const attributeData = ref(
      Form.make({
        identifier: '',
        type: '',
        is_translatable: true,
        translations: {},
        options: [],
      })
    );
    const translationsList = TRANSLATIONS;

    const attributeTypeList = ATTRIBUTE_TYPE_LIST;

    if (id == 'new') {
      viewType.value = 'new';
    } else {
      viewType.value = 'edit';
      dataLoading.value = true;
      AttributesService.getAttributeDetails(id).then((response) => {
        attributeData.value.fill(response.data.data);
        dataLoading.value = false;
      });
    }

    function addAttribute() {
      AttributesService.addAttribute(attributeData.value).then((response) => {
        attributeData.value.fill(response.data.data);
      });
    }
    function updateAttribute() {
      AttributesService.updateAttribute(id, attributeData.value).then(
        (response) => {
          attributeData.value.fill(response.data.data);
        }
      );
    }

    function translationErrorExist(...path) {
      return Object.keys(attributeData.value.errors.all()).filter(function (
        key
      ) {
        return key.includes(path.join('.'));
      }).length;
    }

    const disclosureState = ref({});
    watch(
      () => [attributeData.value.errors, attributeData.value],
      () => {
        translationsList.forEach(function (translation) {
          if (
            translationErrorExist('translations', translation.value) ||
            localStorage.getItem('app_lang') === translation.value
          ) {
            set(disclosureState.value, translation.value, true);
          }
        });
        attributeData.value.options.forEach(function (option, index) {
          translationsList.forEach(function (translation) {
            if (
              translationErrorExist(
                'options',
                index,
                'translations',
                translation.value
              ) ||
              localStorage.getItem('app_lang') === translation.value
            ) {
              set(
                disclosureState.value,
                ['options', index, translation.value],
                true
              );
            }
          });
        });
      },
      { immediate: true, deep: true }
    );

    function addOption() {
      attributeData.value.options.push({});
    }

    function removeOption(index) {
      attributeData.value.options.splice(index, 1);
    }

    function toggleTranslation(...path) {
      let currentState = get(disclosureState.value, path, false);
      set(disclosureState.value, path, !currentState);
    }

    function showTranslation(...path) {
      return get(disclosureState.value, path, false);
    }

    function inputValue(...fieldName) {
      let currentValue = get(attributeData.value, fieldName, null);
      if (!currentValue && fieldName[0] === 'options') {
        unset(attributeData.value, fieldName.slice(0, -1));
      }
      return currentValue;
    }

    function inputEvent(event, ...fieldName) {
      set(attributeData.value, fieldName, event.target.value);
    }

    const showOptions = computed(() =>
      ATTRIBUTES_WITH_OPTIONS.includes(attributeData.value.type)
    );

    return {
      attributeData,
      addAttribute,
      viewType,
      updateAttribute,
      dataLoading,
      translationsList,
      translationErrorExist,
      attributeTypeList,
      addOption,
      removeOption,
      toggleTranslation,
      showTranslation,
      inputValue,
      inputEvent,
      showOptions,
    };
  },
};
</script>
