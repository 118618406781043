<template>
  <div>
    <SectionHeading
      :title="viewType == 'new' ? 'New' : 'Edit'"
      iconName="OfficeBuildingIcon"
    />
    <LoadingSpinner v-if="viewType == 'edit' && dataLoading" />
    <div class="max-w-7xl py-10 sm:px-6 lg:px-8">
      <FormSection @submitted="viewType == 'new' ? addUser() : updateUser()">
        <template #title>
          {{ $t('views.employees.employees.title') }}
        </template>

        <template #description>Manage your employees</template>

        <template #form>
          <div class="col-span-6 sm:col-span-4">
            <Label for="first_name" value="First Name" />
            <Input
              id="first_name"
              type="text"
              class="mt-1 block w-full"
              v-model="userData.first_name"
            />
            <InputError
              :message="userData.errors.get('first_name')"
              class="mt-2"
            />
          </div>

          <div class="col-span-6 sm:col-span-4">
            <Label for="last_name" value="Last Name" />
            <Input
              id="last_name"
              type="text"
              class="mt-1 block w-full"
              v-model="userData.last_name"
            />
            <InputError
              :message="userData.errors.get('last_name')"
              class="mt-2"
            />
          </div>

          <div class="col-span-6 sm:col-span-4">
            <Label for="gender" value="Gender" />
            <TSelect
              id="gender"
              :options="genderList"
              class="mt-1 block w-full"
              v-model="userData.gender"
              textAttribute="label"
            />
            <InputError :message="userData.errors.get('gender')" class="mt-2" />
          </div>

          <div class="col-span-6 sm:col-span-4">
            <Label for="date_of_birth" value="Date of birth" />
            <DatePicker
              class="inline-block mt-1 w-full"
              v-model="userData.date_of_birth"
            >
              <template v-slot="{ inputValue, togglePopover }">
                <div class="flex items-center" @click="togglePopover()">
                  <button
                    type="button"
                    class="
                      px-2
                      py-3
                      h-full
                      bg-blue-100
                      border border-blue-200
                      hover:bg-blue-200
                      text-blue-600
                      rounded-bl-md rounded-tl-md
                      focus:bg-blue-500
                      focus:text-white
                      focus:border-blue-500
                      focus:outline-none
                    "
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      class="w-4 h-4 fill-current"
                    >
                      <path
                        d="M1 4c0-1.1.9-2 2-2h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V4zm2 2v12h14V6H3zm2-6h2v2H5V0zm8 0h2v2h-2V0zM5 9h2v2H5V9zm0 4h2v2H5v-2zm4-4h2v2H9V9zm0 4h2v2H9v-2zm4-4h2v2h-2V9zm0 4h2v2h-2v-2z"
                      ></path>
                    </svg>
                  </button>
                  <input
                    :value="inputValue"
                    class="
                      block
                      w-full
                      border border-gray-300
                      rounded-br-md rounded-tr-md
                      shadow-sm
                      py-2
                      px-3
                      focus:outline-none
                    "
                  />
                </div>
              </template>
            </DatePicker>
            <InputError
              :message="userData.errors.get('date_of_birth')"
              class="mt-2"
            />
          </div>

          <div class="col-span-6 sm:col-span-4">
            <Label for="phone_prefix" value="Phone prefix" />
            <Input
              id="phone_prefix"
              type="text"
              class="mt-1 block w-full"
              v-model="userData.phone_prefix"
            />
            <InputError
              :message="userData.errors.get('phone_prefix')"
              class="mt-2"
            />
          </div>

          <div class="col-span-6 sm:col-span-4">
            <Label for="phone_number" value="Phone number" />
            <Input
              id="phone_number"
              type="text"
              class="mt-1 block w-full"
              v-model="userData.phone_number"
            />
            <InputError
              :message="userData.errors.get('phone_number')"
              class="mt-2"
            />
          </div>

          <div class="col-span-6 sm:col-span-4">
            <Label for="email" value="Email" />
            <Input
              id="email"
              type="text"
              class="mt-1 block w-full"
              v-model="userData.email"
            />
            <InputError :message="userData.errors.get('email')" class="mt-2" />
          </div>

          <div class="col-span-6 sm:col-span-4">
            <Label for="role" value="Roles" />
            <TRichSelect
              class="mt-1 block w-full"
              v-model="userData.roles"
              :options="rolesList.data"
              valueAttribute="id"
              textAttribute="description"
              multiple
              tags
              placeholder="Wybierz rolę"
            />
            <InputError :message="userData.errors.get('roles')" class="mt-2" />
          </div>

          <div v-if="viewType == 'new'" class="col-span-6 sm:col-span-4">
            <Label for="password" value="Hasło" />
            <Input
              id="password"
              type="password"
              class="mt-1 block w-full"
              v-model="userData.password"
              ref="password"
              autocomplete="new-password"
            />
            <InputError
              :message="userData.errors.get('password')"
              class="mt-2"
            />
          </div>

          <div v-if="viewType == 'new'" class="col-span-6 sm:col-span-4">
            <Label for="password_confirmation" value="Potwierdź hasło" />
            <Input
              id="password_confirmation"
              type="password"
              class="mt-1 block w-full"
              v-model="userData.password_confirmation"
              autocomplete="new-password"
            />
            <InputError
              :message="userData.errors.get('password_confirmation')"
              class="mt-2"
            />
          </div>
        </template>

        <template #actions>
          <ActionMessage :on="userData.recentlySuccessful" class="mr-3">
            Saved.
          </ActionMessage>

          <Button
            :class="{ 'opacity-25': userData.busy }"
            :disabled="userData.busy"
          >
            {{ viewType == 'new' ? 'Add' : 'Update' }}
          </Button>
        </template>
      </FormSection>

      <SectionBorder v-if="viewType == 'edit'" />

      <UpdatePasswordForm v-if="viewType == 'edit'" />
    </div>
  </div>
</template>

<script>
import { computed, ref } from 'vue';
import EmployeesService from '@/service/employees/employees.js';
import { useRoute } from 'vue-router';
import LoadingSpinner from '@/components/@globals/helpers/LoadingSpinner';
import { GENDERLIST } from './../customers/customers/customer-constants';
import FormSection from '../../../components/@globals/helpers/FormSection';
import Input from '../../../components/@globals/helpers/Input';
import InputError from '../../../components/@globals/helpers/InputError';
import Button from '../../../components/@globals/helpers/Button';
import Label from '../../../components/@globals/helpers/Label';
import { TSelect, TRichSelect } from '@variantjs/vue';
import { useStore } from 'vuex';
import SectionBorder from '../../../components/@globals/helpers/SectionBorder';
import UpdatePasswordForm from './partials/UpdatePasswordForm';
import ActionMessage from '../../../components/@globals/helpers/ActionMessage';
import Form from 'vform';
export default {
  name: 'EmployeesAddEdit',
  components: {
    UpdatePasswordForm,
    SectionBorder,
    TSelect,
    Button,
    InputError,
    Input,
    FormSection,
    LoadingSpinner,
    Label,
    TRichSelect,
    ActionMessage,
  },
  setup() {
    const store = useStore();
    const {
      params: { id },
    } = useRoute();
    const viewType = ref('');
    const dataLoading = ref(false);
    const userData = ref(
      Form.make({
        first_name: '',
        last_name: '',
        gender: '',
        date_of_birth: '',
        phone_prefix: '',
        phone_number: '',
        email: '',
        roles: [],
        password: '',
        password_confirmation: '',
      })
    );

    store.dispatch('getRoles', {});
    const rolesList = computed(() => store.getters.getRoles);

    if (id == 'new') {
      viewType.value = 'new';
    } else {
      viewType.value = 'edit';
      dataLoading.value = true;
      EmployeesService.getEmployeeDetails(id).then((response) => {
        userData.value.fill(response.data.data);
        userData.value.roles = response.data.data.roles.map((role) => role.id);
        dataLoading.value = false;
      });
    }

    function addUser() {
      EmployeesService.addEmployee(userData.value);
    }
    function updateUser() {
      EmployeesService.updateEmployee(id, userData.value);
    }

    const genderList = GENDERLIST;

    return {
      genderList,
      userData,
      addUser,
      viewType,
      updateUser,
      dataLoading,
      rolesList,
    };
  },
};
</script>
