<template>
  <div>
    <SectionHeading
      :title="viewType == 'new' ? $t('common.new') : $t('common.update')"
      iconName="ArchiveIcon"
    />
    <LoadingSpinner v-if="dataLoading" />
    <div class="max-w-7xl py-10 sm:px-6 lg:px-8">
      <FormSection @submitted="viewType == 'new' ? addDepot() : updateDepot()">
        <template #title>
          {{ $t('views.resources.depots.title') }}
        </template>

        <template #description>{{
          $t('views.resources.depots.subtitle')
        }}</template>

        <template #form>
          <div class="col-span-6 sm:col-span-4">
            <Label for="identifier" value="Identyfikator" />
            <Input
              id="identifier"
              type="text"
              class="mt-1 block w-full"
              v-model="depotData.identifier"
            />
            <InputError
              :message="depotData.errors.get('identifier')"
              class="mt-2"
            />
          </div>

          <div class="col-span-6 sm:col-span-4">
            <Label for="zip_code" value="Przypisz kody pocztowe" />
            <TRichSelect
              class="mt-1 block w-full"
              v-model="depotData.zip_code_ids"
              :options="zipCodeList.data"
              valueAttribute="id"
              textAttribute="code"
              multiple
              tags
              placeholder="Wybierz kody pocztowe"
            />
            <InputError
              :message="depotData.errors.get('zip_code_ids')"
              class="mt-2"
            />
          </div>
        </template>

        <template #actions>
          <ActionMessage :on="depotData.recentlySuccessful" class="mr-3">
            Saved.
          </ActionMessage>

          <Button
            :class="{ 'opacity-25': depotData.busy }"
            :disabled="depotData.busy"
          >
            {{ viewType == 'new' ? $t('common.add') : $t('common.update') }}
          </Button>
        </template>
      </FormSection>

      <SectionBorder v-if="viewType == 'edit'" />
    </div>
  </div>
</template>

<script>
import { computed, ref } from 'vue';
import { useRoute } from 'vue-router';
import DepotService from '@/service/depots/depots.js';
import LoadingSpinner from '@/components/@globals/helpers/LoadingSpinner';
import FormSection from '../../../components/@globals/helpers/FormSection';
import Input from '../../../components/@globals/helpers/Input';
import InputError from '../../../components/@globals/helpers/InputError';
import Button from '../../../components/@globals/helpers/Button';
import Label from '../../../components/@globals/helpers/Label';
import { TRichSelect } from '@variantjs/vue';
import { useStore } from 'vuex';
import SectionBorder from '../../../components/@globals/helpers/SectionBorder';
import ActionMessage from '../../../components/@globals/helpers/ActionMessage';
import Form from 'vform';

export default {
  name: 'DepotAddEdit',
  components: {
    SectionBorder,
    Button,
    InputError,
    Input,
    FormSection,
    LoadingSpinner,
    Label,
    TRichSelect,
    ActionMessage,
  },
  setup() {
    const store = useStore();
    const {
      params: { id },
    } = useRoute();

    const viewType = ref('');
    const dataLoading = ref(false);
    const depotData = ref(
      Form.make({
        identifier: '',
        zip_code_ids: [],
      })
    );

    store.dispatch('getZipCodes', {});
    const zipCodeList = computed(() => store.getters.getZipCodes);

    if (id == 'new') {
      viewType.value = 'new';
    } else {
      viewType.value = 'edit';
      dataLoading.value = true;
      DepotService.getDepotDetails(id).then((response) => {
        depotData.value.fill(response.data.data);
        depotData.value.zip_code_ids = response.data.data.zip_codes.map(
          (zip_codes) => zip_codes.id
        );
        dataLoading.value = false;
      });
    }

    function addDepot() {
      DepotService.addDepot(depotData.value);
    }

    function updateDepot() {
      DepotService.updateDepot(id, depotData.value);
    }

    return {
      depotData,
      addDepot,
      viewType,
      updateDepot,
      dataLoading,
      zipCodeList,
    };
  },
};
</script>
