<template>
  <div>
    <SectionHeading
      :title="viewType == 'new' ? 'New' : 'Edit'"
      iconName="NewspaperIcon"
    />
    <LoadingSpinner v-if="viewType == 'edit' && dataLoading" />
    <div class="max-w-7xl py-10 sm:px-6 lg:px-8">
      <FormSection @submitted="updateNewsletterInterest()">
        <template #title>
          {{ $t('views.newsletter_interests.newsletter_interests.title') }}
        </template>

        <template #description>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Autem,
          repellendus!
        </template>

        <template #form>
          <div class="col-span-6 sm:col-span-4">
            <Label for="name" value="Name" />
            <Input
              id="name"
              type="text"
              class="mt-1 block w-full"
              v-model="newsletterInterestData.name"
            />
            <InputError
              :message="newsletterInterestData.errors.get('name')"
              class="mt-2"
            />
          </div>
        </template>

        <template #actions>
          <ActionMessage
            :on="newsletterInterestData.recentlySuccessful"
            class="mr-3"
          >
            Saved.
          </ActionMessage>

          <Button
            :class="{ 'opacity-25': newsletterInterestData.busy }"
            :disabled="newsletterInterestData.busy"
          >
            {{ viewType == 'new' ? 'Add' : 'Update' }}
          </Button>
        </template>
      </FormSection>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import NewsletterInterestsService from '@/service/newsletter-interests/newsletterInterests.js';
import { useRoute } from 'vue-router';
import LoadingSpinner from '@/components/@globals/helpers/LoadingSpinner';
import FormSection from '../../../components/@globals/helpers/FormSection';
import Input from '../../../components/@globals/helpers/Input';
import InputError from '../../../components/@globals/helpers/InputError';
import Button from '../../../components/@globals/helpers/Button';
import ActionMessage from '../../../components/@globals/helpers/ActionMessage';
import Label from '../../../components/@globals/helpers/Label';
import Form from 'vform';
export default {
  name: 'NewsletterInterestsAddEdit',
  components: {
    Button,
    ActionMessage,
    InputError,
    Input,
    FormSection,
    LoadingSpinner,
    Label,
  },
  setup() {
    const {
      params: { id },
    } = useRoute();
    const viewType = ref('');
    const dataLoading = ref(false);
    const newsletterInterestData = ref(
      Form.make({
        name: '',
      })
    );

    if (id == 'new') {
      viewType.value = 'new';
    } else {
      viewType.value = 'edit';
      dataLoading.value = true;
      NewsletterInterestsService.getNewsletterInterestDetails(id).then(
        (response) => {
          newsletterInterestData.value.fill(response.data.data);
          dataLoading.value = false;
        }
      );
    }

    function updateNewsletterInterest() {
      NewsletterInterestsService.updateNewsletterInterest(
        id,
        newsletterInterestData.value
      );
    }

    return {
      newsletterInterestData,
      viewType,
      updateNewsletterInterest,
      dataLoading,
    };
  },
};
</script>
