<template>
  <div>
    <SectionHeading
      :title="viewType === 'new' ? 'New' : 'Edit'"
      iconName="UserGroupIcon"
    />

    <div class="max-w-7xl py-10 sm:px-6 lg:px-8 relative">
      <LoadingSpinner v-if="viewType === 'edit' && dataLoading" />
      <FormSection @submitted="viewType === 'new' ? addUser() : updateUser()">
        <template #title>
          {{ $t('views.customers.customers.title') }}
        </template>

        <template #description>Manage your customers</template>

        <template #form>
          <h3 class="col-span-6 text-lg leading-6 font-medium text-gray-900">
            Dane ogólne
          </h3>

          <div class="col-span-6 sm:col-span-4">
            <Label for="account_type" value="Account Type" />
            <TSelect
              id="account_type"
              :options="accountTypeList"
              class="mt-1 block w-full"
              v-model="userData.account_type"
              textAttribute="label"
              valuetAttribute="value"
            />
            <InputError
              :message="userData.errors.get('account_type')"
              class="mt-2"
            />
          </div>

          <div class="col-span-6 sm:col-span-4">
            <Label for="email" value="Email" />
            <Input
              id="email"
              type="text"
              class="mt-1 block w-full"
              v-model="userData.email"
            />
            <InputError :message="userData.errors.get('email')" class="mt-2" />
          </div>

          <div class="col-span-6 sm:col-span-4">
            <Label for="has_account" value="Has account" />
            <TToggle
              id="has_account"
              class="mt-1 block"
              v-model="userData.has_account"
            />
            <InputError
              :message="userData.errors.get('has_account')"
              class="mt-2"
            />
          </div>

          <div
            v-if="viewType === 'new' && userData.has_account"
            class="col-span-6 sm:col-span-4"
          >
            <Label for="password" value="Password" />
            <Input
              id="password"
              type="password"
              class="mt-1 block w-full"
              v-model="userData.password"
              ref="password"
              autocomplete="new-password"
            />
            <InputError
              :message="userData.errors.get('password')"
              class="mt-2"
            />
          </div>

          <div
            v-if="viewType === 'new' && userData.has_account"
            class="col-span-6 sm:col-span-4"
          >
            <Label for="password_confirmation" value="Password Confirmation" />
            <Input
              id="password_confirmation"
              type="password"
              class="mt-1 block w-full"
              v-model="userData.password_confirmation"
              autocomplete="new-password"
            />
            <InputError
              :message="userData.errors.get('password_confirmation')"
              class="mt-2"
            />
          </div>

          <h3 class="col-span-6 text-lg leading-6 font-medium text-gray-900">
            Dane Personalne
          </h3>

          <div class="col-span-6 sm:col-span-4">
            <Label for="first_name" value="First Name" />
            <Input
              id="first_name"
              type="text"
              class="mt-1 block w-full"
              v-model="userData.first_name"
            />
            <InputError
              :message="userData.errors.get('first_name')"
              class="mt-2"
            />
          </div>

          <div class="col-span-6 sm:col-span-4">
            <Label for="last_name" value="Last Name" />
            <Input
              id="last_name"
              type="text"
              class="mt-1 block w-full"
              v-model="userData.last_name"
            />
            <InputError
              :message="userData.errors.get('last_name')"
              class="mt-2"
            />
          </div>

          <div class="col-span-6 sm:col-span-4">
            <Label for="gender" value="Gender" />
            <TSelect
              id="gender"
              :options="genderList"
              class="mt-1 block w-full"
              v-model="userData.gender"
              textAttribute="label"
            />
            <InputError :message="userData.errors.get('gender')" class="mt-2" />
          </div>

          <div class="col-span-6 sm:col-span-4">
            <Label for="date_of_birth" value="Date of birth" />
            <DatePicker
              class="inline-block mt-1 w-full"
              v-model="userData.date_of_birth"
            >
              <template v-slot="{ inputValue, togglePopover }">
                <div class="flex items-center" @click="togglePopover()">
                  <button
                    type="button"
                    class="px-2 py-3 h-full bg-blue-100 border border-blue-200 hover:bg-blue-200 text-blue-600 rounded-bl-md rounded-tl-md focus:bg-blue-500 focus:text-white focus:border-blue-500 focus:outline-none"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      class="w-4 h-4 fill-current"
                    >
                      <path
                        d="M1 4c0-1.1.9-2 2-2h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V4zm2 2v12h14V6H3zm2-6h2v2H5V0zm8 0h2v2h-2V0zM5 9h2v2H5V9zm0 4h2v2H5v-2zm4-4h2v2H9V9zm0 4h2v2H9v-2zm4-4h2v2h-2V9zm0 4h2v2h-2v-2z"
                      ></path>
                    </svg>
                  </button>
                  <input
                    :value="inputValue"
                    class="block w-full border border-gray-300 rounded-br-md rounded-tr-md shadow-sm py-2 px-3 focus:outline-none"
                  />
                </div>
              </template>
            </DatePicker>
            <InputError
              :message="userData.errors.get('date_of_birth')"
              class="mt-2"
            />
          </div>

          <div class="col-span-6 sm:col-span-4">
            <Label for="phone_prefix" value="Phone prefix" />
            <Input
              id="phone_prefix"
              type="text"
              class="mt-1 block w-full"
              v-model="userData.phone_prefix"
            />
            <InputError
              :message="userData.errors.get('phone_prefix')"
              class="mt-2"
            />
          </div>

          <div class="col-span-6 sm:col-span-4">
            <Label for="phone_number" value="Phone number" />
            <Input
              id="phone_number"
              type="text"
              class="mt-1 block w-full"
              v-model="userData.phone_number"
            />
            <InputError
              :message="userData.errors.get('phone_number')"
              class="mt-2"
            />
          </div>

          <div class="col-span-6 sm:col-span-4">
            <Label for="vat_number" value="KVK" />
            <Input
              id="vat_number"
              type="text"
              class="mt-1 block w-full"
              v-model="userData.vat_number"
            />
            <InputError
              :message="userData.errors.get('vat_number')"
              class="mt-2"
            />
          </div>

          <div class="col-span-6 sm:col-span-4">
            <Label for="company_name" value="Company Name" />
            <Input
              id="company_name"
              type="text"
              class="mt-1 block w-full"
              v-model="userData.company_name"
            />
            <InputError
              :message="userData.errors.get('company_name')"
              class="mt-2"
            />
          </div>
        </template>

        <template #actions>
          <ActionMessage :on="userData.recentlySuccessful" class="mr-3">
            Saved.
          </ActionMessage>

          <Button
            :class="{ 'opacity-25': userData.busy }"
            :disabled="userData.busy"
          >
            {{ viewType === 'new' ? 'Add' : 'Update' }}
          </Button>
        </template>
      </FormSection>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import CustomersService from '@/service/customers/customers.js';
import { useRoute } from 'vue-router';
import LoadingSpinner from '@/components/@globals/helpers/LoadingSpinner';
import { GENDERLIST, ACCOUNTTYPELIST } from './customer-constants';
import Button from '../../../../components/@globals/helpers/Button';
import FormSection from '../../../../components/@globals/helpers/FormSection';
import InputError from '../../../../components/@globals/helpers/InputError';
import ActionMessage from '../../../../components/@globals/helpers/ActionMessage';
import { TSelect, TToggle } from '@variantjs/vue';
import Input from '../../../../components/@globals/helpers/Input';
import Label from '../../../../components/@globals/helpers/Label';
import Form from 'vform';
import router from '@/router';
export default {
  name: 'CustomersAddEdit',
  components: {
    Button,
    LoadingSpinner,
    FormSection,
    InputError,
    TSelect,
    TToggle,
    Input,
    ActionMessage,
    Label,
  },
  setup() {
    const {
      params: { id },
    } = useRoute();
    const viewType = ref('');
    const dataLoading = ref(false);
    const userData = ref(
      Form.make({
        account_type: '',
        email: '',
        first_name: '',
        last_name: '',
        gender: '',
        date_of_birth: '',
        phone_prefix: '',
        phone_number: '',
        company_name: '',
        vat_number: '',
        password: '',
        password_confirmation: '',
        has_account: false,
      })
    );

    if (id === 'new') {
      viewType.value = 'new';
    } else {
      viewType.value = 'edit';
      dataLoading.value = true;
      CustomersService.getCustomerDetails(id).then((response) => {
        userData.value.fill(response.data.data);
        dataLoading.value = false;
      });
    }

    const accountTypeList = ACCOUNTTYPELIST;

    function addUser() {
      CustomersService.addCustomer(userData.value).then(() => {
        router.push({ name: 'CustomersList' });
      });
    }
    function updateUser() {
      CustomersService.updateCustomer(id, userData.value).then(() => {
        router.push({ name: 'CustomersList' });
      });
    }

    const genderList = GENDERLIST;

    return {
      accountTypeList,
      genderList,
      userData,
      addUser,
      viewType,
      updateUser,
      dataLoading,
    };
  },
};
</script>
